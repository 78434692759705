.Logo {
  margin-top: 40px;
}
.Footer {
  height: 100%;
  background: #ae1857;
  margin-top: 60px;
  width: 100%;
}
.LogoText {
  font-weight: 200;
  margin-top: 25px;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  color: #ffffff;
}
.Title {
  margin-bottom: 20px;
  margin-top: 30px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  font-size: 18px;
  color: #ffffff;
}
.Links {
  color: #ffffff;
  font-weight: 200;
  font-size: 15px;
  font-family: "gilroyNormal" !important;
  margin-bottom: 4px !important;
}
.Link {
  margin-top: 80px;
}
.Icons {
  margin-top: -25px;
  margin-bottom: 80px;
}
.Icons img {
  margin: 7px;
}
.FooterDown {
  background: #7b1b39;
  /* height: 100%; */
}
.FooterIcons {
  margin: 20px 5px 10px 5px;
  width: 20px;
  height: 20px;
}
.Address {
  display: flex;
}
.AddressText {
  margin-left: 15px;
}
.FooterDownText {
  color: #ffffff;
  font-family: "gilroyNormal" !important;
  margin: 20px 0px 10px 0px;
  font-size: 10px;
}
.FooterDownTextR {
  color: #ffffff;
  font-family: "gilroyNormal" !important;
  margin: 20px 0px 10px 0px;
  font-size: 10px;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .Icons img {
    width: 25px;
  }
  .FooterDownText {
    text-align: center;
  }
  .FooterDownTextR {
    text-align: center;
  }
  .FootIconImg {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1200px) {
  .Icons img {
    width: 23px;
  }
}
