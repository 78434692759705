.TopDemanded {
  margin-top: 50px;
}

.MainText {
  font-weight: 500;
  font-size: 38px;
  text-align: center;
  color: #0d1217;
  font-family: "PlayFairNormal" !important;
}
.TopDemnd {
  display: flex;
  justify-content: space-between;
}

.SubText {
  font-weight: 200;
  font-size: 18px;
  color: #757c81;
  text-align: center;
  line-height: 0.2;
  font-family: "gilroyNormal" !important;
}
.Products {
  display: flex;
  align-items: center;
}
.seeAll {
  font-size: 16px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  color: #ae1857;
  cursor: pointer;
  margin-top: 70px;
}
@media screen and (max-width: 500px) {
  .MainText {
    font-size: 25px;
  }
  .seeAll {
    margin-top: 57px;
  }
}
