.ImageContainer {
  position: relative;
  background-image: url(../../Assets/mocup.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-height: 350px;
  margin-top: 35px;
}
.ImageContainerMob {
  position: relative;
  background-image: url(../../Assets/downBgMob.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  margin-top: 35px;
}

.Image {
  display: block;
  margin: auto;
  width: 100%;
}
.ImageContainer p {
  color: rgba(4, 26, 37, 0.6);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-left: 18%;
}
.Web {
  display: block;
}
.Mob {
  display: none;
}
.AppLink {
  padding-left: 18%;
}
.Head {
  font-weight: 700;
  font-size: 35px;
  line-height: 53px;
  color: #041a25;
  padding-left: 18%;
  padding-top: 5%;
  padding-bottom: 10px;
}
/* .AppStore {
    position: absolute;
    z-index: 2;
    left: 23%;
    transform: translateX(-50%);
    top: 60%;
  }
   */
.Gplay {
  margin-left: 30px;
}

@media screen and (max-width: 969px) {
  .Head {
    padding-top: 13%;
    font-size: 25px;
    padding-bottom: 0px;
  }

  .ImageContainer p {
    color: rgba(4, 26, 37, 0.6);
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    padding-left: 18%;
  }
}
@media screen and (max-width: 1165px) {
}
@media screen and (max-width: 734px) {
  .Image {
    margin-top: 0;
  }
  .ImageContainer {
    position: relative;
    background-image: url(../../Assets/downBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 280px;
    margin-top: 35px;
  }
  .Mob {
    display: block;
  }
  .Head {
    font-size: 27px;
    padding-left: 10%;
  }
  .Web {
    display: none;
  }
  .ImageContainer p {
    color: rgba(4, 26, 37, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10%;
  }
  .ImageContainerMob p {
    color: rgba(4, 26, 37, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 6%;
  }

  .AppLink {
    padding-left: 5%;
  }
}
@media screen and (max-width: 370px) {
  .Image {
    margin-top: 0;
  }
  .ImageContainer {
    position: relative;
    background-image: url(../../Assets/downBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 280px;
    margin-top: 35px;
  }
  .Head {
    font-size: 27px;
    padding-left: 5%;
  }
  .ImageContainer p {
    color: rgba(4, 26, 37, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-left: 5%;
  }
  .AppLink {
    padding-left: 5%;
  }
}
