.NewArrivalsMainText {
  font-weight: 500;
  font-size: 38px;
  color: #0d1217;
  font-family: "PlayFairNormal" !important;
}
.setItems {
  display: flex;
  justify-content: space-between;
}

.NewArrivalsSubText {
  font-weight: 400;
  font-size: 18px;

  color: #757c81;
  margin-top: 20px;
  font-family: "gilroyNormal" !important;
}
.seeAll {
  font-size: 16px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
  color: #ae1857;
  cursor: pointer;
  margin-top: 67px;
}
@media screen and (max-width: 767px) {
  .NewArrivalsMainText {
    font-size: 28px;
  }
  .seeAll {
    font-size: 15px;
    margin-top: 53px;
  }
  .NewArrivalsSubText {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .NewArrivalsMainText {
    font-size: 25px;
  }
  .seeAll {
    font-size: 13px;
  }
}
