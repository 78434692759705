.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}
.Main {
  display: flex;
}
.SubText {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  border-bottom: 1px solid #e8e8e8;
}
.Home {
  color: #ae1857;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  cursor: pointer;
}
.Wishlist {
  color: #01b3c5;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}
