@font-face {
  font-family: "gilroyNormal";
  src: url(./Font/Gilroy-Regular.ttf);
}

@font-face {
  font-family: "PlayFairNormal";
  src: url(./Font/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: "InterNormal";
  src: url(./Font/Inter-Regular.ttf);
}

@font-face {
  font-family: "GilroyItalics";
  src: url(./Font/Gilroy-MediumItalic.ttf);
}

@font-face {
  font-family: "GilroyRegular";
  src: url(./Font/Gilroy-Regular.ttf);
}

.page-link {
  font-weight: 800 !important;
  font-size: 16px !important;
}
.pagination {
  --bs-pagination-padding-x: 1rem !important;
  --bs-pagination-padding-y: 0.4rem !important;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #000000 !important;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 0px !important;
  --bs-pagination-border-color: #dee2e6;

  --bs-pagination-border-radius: 0.5rem !important;
  --bs-pagination-hover-color: #fff !important;
  --bs-pagination-hover-bg: #000000 !important;
  --bs-pagination-hover-border-color: #000000 !important;
  --bs-pagination-focus-color: #fff !important;
  --bs-pagination-focus-bg: #000000 !important;
  --bs-pagination-focus-box-shadow: none !important;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #000000 !important;
  --bs-pagination-active-border-color: #000000 !important;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.padLeft {
  padding-left: 0px !important;
}
input::placeholder {
  text-align: left !important;
  padding-left: 0px !important;
}

.btnPad {
  padding-top: 20px;
}
.my-modal {
  max-width: 30vw !important;
}
.contBg {
  background-color: #fefefe;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* 
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
  background: #0EB533 !important;
  /* height: 140% !important; */
/* margin-top: -10px !important; */

/* .ant-steps-icon-dot {
  background: #0EB533 !important;
} */
.carousel-indicators {
  display: none !important;
}
.carousel-control-next-icon {
  display: none !important;
}
.errrMsg {
  font-size: 16px;
  color: #c1380a;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
}
.carousel-control-prev-icon {
  display: none !important;
}
.carousel {
  z-index: -1 !important;
}
.samPle {
  display: flex;
  justify-content: space-between;
}
.vl {
  border-right: 1px solid #e9e9e9;
  min-height: 200px;
}
.modal-header {
  border: none !important;
}
.loader {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pageout {
  padding-top: 20px;
  padding-right: 20px;
}
.clear {
  display: flex;
  justify-content: space-between;
}
.pad-10 {
  padding-top: 30px !important;
}
.modal-body {
  padding: 0 50px 25px 50px !important;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #0eb533 !important;
}
.ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #c7c6c6 !important;
}
.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #0eb533 !important;
}
.ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  padding: 0px !important;
}
.ant-steps-item-title {
  font-size: 15px !important;
}
.modal-content {
  top: 50px !important;
  width: 96% !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  border-bottom: 1px solid #e2e4e5 !important;
  border-radius: 0 !important;
}
.mar-10 {
  padding-top: 25px;
}
.modal-body {
  padding: 0px 30px 25px 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 565px) {
  .loader {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

@media (min-width: 300px) and (max-width: 567px) {
  .pageout {
    padding-top: 20px;
    padding-right: 0px !important;
  }
  .nopadmar{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .page-link {
    font-weight: 800 !important;
    font-size: 12px !important;
  }
}
