.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}

.Main {
  display: flex;
}

.SubText {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  border-bottom: 1px solid #e8e8e8;
}

.Home {
  color: #ae1857;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}

.Wishlist {
  color: #01b3c5;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}

.Flex {
  display: flex;
}

.ReviewProduct {
  display: flex;
  background: #ffffff;
  height: 80px;
  border: 0.5px solid #e6e6e6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-family: "gilroyNormal" !important;
}

.ProductImage {
  padding: 10px;
}

.ProductName {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0d1217;
  font-family: "gilroyNormal" !important;
  font-weight: 500;
  padding: 10px 0 0 5px;
}

.RatingText {
  font-weight: 600;
  color: #0d1217;
  font-size: 16px;
  font-family: "gilroyNormal" !important;
  padding: 15px 30px 0 0px;
}

.RatingStar {
  display: flex;
  border-bottom: 0.5px solid #e8e9ea;
  align-items: center;
}
.Star {
  margin: 0 5px 0 5px;
}
.ReviewPart {
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 0 30px 0 30px;
}
.Input {
  width: 100%;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #e8e9ea;
  border-radius: 4px;
}
.ReviewText {
  font-weight: 600;
  color: #0d1217;
  font-family: "gilroyNormal" !important;
  font-size: 16px;
}
.Review {
  padding: 10px 0px 20px 0px;
}
.Button {
  background: #19242d;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px 25px;
}
.Submit {
  text-align: end;
  margin-top: 10px;
}
.Check {
  margin-left: 55%;
}
.Thanks {
  font-family: "gilroyNormal" !important;
  text-align: center;

  font-weight: 400;
  font-size: 16px;
}
