.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}

.Main {
  display: flex;
  margin-top: 30px;
}

.SubText {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  border-bottom: 0.5px solid #e8e9ea;
  padding-bottom: 30px;
}

.Home {
  color: #ae1857;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}

.PrivacyPolicy {
  color: #01b3c5;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}

.Description {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #757c81;
  margin-top: 25px;
  font-family: "gilroyNormal" !important;
}

.SubHeading {
  font-weight: 600;
  font-size: 18px;
  color: #ae1857;
  font-family: "gilroyNormal" !important;
}
