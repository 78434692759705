


.Name {
    color: #475057;
    font-weight: 600;
    font-size: 15px;
    font-family: 'gilroyNormal' !important;
}

.Address {
    font-weight: 400;
    font-size: 14px;
    color: #475057;
    font-family: 'gilroyNormal' !important;
}

.ContactUs {
    background: #006E7F;
    border: 1px solid #006E7F;
    border-radius: 32px;
    color: #FFFFFF;
    font-weight: 400;
font-size: 14px;
margin: 20px 15px 5px 15px;
padding:8PX 10px 8px 10px;
font-family: 'gilroyNormal' !important;
}
.NeedHelp{
    color: #0D1217;
    font-weight: 600;
    font-family: 'gilroyNormal' !important;
font-size: 15px;
margin: 30px 15px 0px 15px;
}
.HelpContact{
    display: flex;
    justify-content: flex-end;
    border-top: 0.5px solid #E8E9EA;
    margin-top: 20px;
}
.Flex{
    display: flex;
    justify-content: space-between;
    line-height: .3;
}
.Coupon{
    font-weight: 400;
    font-size: 14px;
    color:#30933A;
    font-family: 'gilroyNormal' !important;
}