.Features {
  margin-bottom: -60px !important;
  margin-top: 20px;
}
.Background {
  background: #eeeeee;
}
.detPad {
  padding-top: 25px;
}
.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}

.Main {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin: 30px 50px 0 50px;
}
.Home {
  color: #ae1857;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  font-weight: 500;
}
.SearchOrder {
  margin: 15px 0 20px 50px;
}
.SearchBar {
  width: 45%;
  background-image: url(../../Assets/searchIcon.png);
  background-repeat: no-repeat;
  background-position: 20px;
  background-size: 15px;
  border: none;
  height: 40px;
  border: 1px solid #e8e9ea;
  border-radius: 4px;

  font-family: "gilroyNormal" !important;
}
.SearchButton {
  background: linear-gradient(180deg, #978958 0%, #aea174 82.81%);
  border-radius: 2px;
  border: none;
  color: #ffffff;
  font-family: "gilroyNormal" !important;
  padding: 10px 11px 10px 11px;
  font-weight: 500;
  font-size: 13px;
  margin-left: 10px;
}
.shipMent {
  min-height: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 0px 50px 30px 50px;
  padding: 25px;
}
.date {
  font-family: "gilroyNormal" !important;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #19242d;
  font-weight: 200 !important;
}
.Adres {
  padding-top: 10px;
  font-family: "gilroyNormal" !important;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #475057;
  margin-bottom: 0px !important;
}
.Box {
  display: flex;
  justify-content: space-between;
}
.head {
  color: #0d1217;
  font-size: 18px;
  font-weight: 600;
  font-family: "gilroyNormal" !important;
}

.NewArrival {
  color: #01b3c5;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  font-weight: 500;
}
.SubText {
  display: flex;

  font-size: 14px;
  font-family: "gilroyNormal" !important;
}
