.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}

.Main {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin: 30px 50px 0 50px;
  border-bottom: 0.5px solid #e8e9ea;
}
.Home {
  color: #ae1857;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  font-weight: 500;
}
.NewArrival {
  color: #01b3c5;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  font-weight: 500;
}
.SubText {
  display: flex;

  font-size: 14px;
  font-family: "gilroyNormal" !important;
}
.DropDown {
  margin-top: 30px;
  margin-left: 40px;
}
.accordion-button {
  background: #ffffff !important;
  border: none !important;
  font-family: "gilroyNormal" !important;
}
.accordion-header:focus {
  background: #ffffff !important;
  border: none !important;
  font-family: "gilroyNormal" !important;
}
.Question {
  font-family: "gilroyNormal" !important;
  font-weight: 500;
  font-size: 16px;
  border: none;
}
.Answer {
  color: #757c81;
  font-weight: 400;
  font-size: 14px;
}
@media screen and (max-width: 500px) {
  .Main {
    margin: 20px 10px 0 10px;
  }
  .DropDown {
    margin-left: 10px;
  }
}
