.Title {
  font-weight: 500;
  font-size: 24px;
  font-family: "PlayFairNormal" !important;
}
.Main {
  display: flex;
}
.Number {
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  color: #757c81;
  font-family: "gilroyNormal" !important;
  line-height: 30px;
}
.Found {
  color: #757c81;
  font-size: 16px;
  line-height: 18px;
  font-family: "gilroyNormal" !important;
}
.SubText {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}
.Home {
  color: #ae1857;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
  cursor: pointer;
}
.NewArrival {
  color: #01b3c5;
  font-weight: 400;
  font-size: 14px;
  font-family: "gilroyNormal" !important;
}
.Filtered {
  display: flex;
}
.FilterBy {
  font-weight: 400;
  font-size: 13px;
  font-family: "gilroyNormal" !important;
}
.Filter {
  background: #f7f6f2;
  border-radius: 16px;
  margin: -5px 10px 10px 10px;
  padding: 5px 8px 5px 8px;
  font-size: 13px;
}
.filterSort {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.SortDrop {
  display: flex;
}
.Sort {
  font-weight: 400;
  font-size: 13px;
  font-family: "gilroyNormal" !important;
}
.SortDrop select {
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-top: -18px;
}

.SortDrop select::selection {
  border: none;
}
@media screen and (max-width: 767px) {
  .Filter {
    padding: 0;
  }
}

@media (min-width: 300px) and (max-width: 567px) {
  .Found {
    margin-bottom: 15px;
  }
}
