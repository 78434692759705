.DropDown {
  display: flex;
  justify-content: space-between;
}

.Filter {
  width: 100% !important;
  margin-top: 15px;
}

.Flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Clear {
  cursor: pointer;
  color: #ae1857;
}
.MainText {
  color: #0d1217;
  font-weight: 600;
  font-size: 16px;
  font-family: "gilroyNormal" !important;
}

.FilterText {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px;
  font-weight: 600;
  font-size: 16px;
  font-family: "gilroyNormal" !important;
}

.Flex label {
  color: #a3a7ab !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: "gilroyNormal" !important;
  user-select: none !important;
  margin-left: 10px;
  padding-bottom: 0;
}

.Flex {
  color: #a3a7ab !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "gilroyNormal" !important;
}

input[type="checkbox"] {
  accent-color: #006e7f !important;
}

input:checked + label {
  font-weight: bold !important;
  color: #475057 !important;
}

input:checked + label + .number {
  font-weight: bold !important;
  color: #475057 !important;
}
.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
}
.number {
  margin-bottom: -3px;
}
@media screen and (max-width: 1400px) {
  .Flex label {
    color: #a3a7ab !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    font-family: "gilroyNormal" !important;
    user-select: none !important;
    margin-left: 10px;
    padding-bottom: 0;
  }
  .number {
    margin-bottom: -3px;
    font-size: 12px !important;
  }
}
