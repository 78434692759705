.ProductName {
  font-weight: 400;
  font-size: 20px;
  color: #0d1217;
  text-align: center;
  font-family: "gilroyNormal" !important;
}
.Discount {
  position: absolute;
  top: 3%;
  left: 70%;
  padding: 7px;
  height: 35px;
}
.Number {
  background: #189e02;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
}
.ProductId {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #757c81;
  line-height: 0.3;
}
.Rupee {
  font-size: 20px;
}

.Price {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px 0 25px;
}

.PriceNew {
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
}

.PriceOld {
  text-decoration: line-through;
  font-weight: 400;
  font-size: 18px;
  color: #b0b0b0;
}

.AddToCart {
  background: #19242d;
  border-radius: 4px;
  width: 70%;
  height: 48px;
  text-align: center;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 12px;
  font-family: "InterNormal" !important;
}

.Heart {
  background: #ae1857;
  border-radius: 4px;
  width: 20%;
  height: 48px;
  cursor: pointer;
  padding: 13px;
  margin-left: 5px;
}
.None {
  display: none;
}
.HeartOnClick {
  border-radius: 4px;
  width: 48px;
  height: 48px;
  padding: 13px;
  margin-left: 5px;
  background: #ffffff;
  border: 1px solid #006e7f;
}
.Buttons {
  display: none;

  /* justify-content: center; */
}
.ProductImage {
  width: 100%;

  padding: 20px;
}
.NewArrivals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.NewArrivals:hover .Buttons {
  display: block;
  transition: 0.8s;
}
.NewArrivalCard {
  width: 100%;
  position: relative;
}
.NewArrivalCard img {
  display: flex;
  justify-content: center;
}
.skuN {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .ProductName {
    font-size: 15px;
  }
  .Price {
    padding: 0 15px 0 15px;
  }
  .NewArrivals .Buttons {
    display: block;
  }

  .Heart {
    width: 25%;
    height: 40px;
  }
  .AddToCart {
    height: 40px;
  }
  .HeartOnClick {
    height: 40px;
  }
}
@media screen and (max-width: 1165px) {
  .Number {
    background: #189e02;
    color: #ffffff;
    padding: 5px;
    font-size: 10px;
  }
}
@media screen and (max-width: 767px) {
  .NewArrivals .Buttons {
    display: block;
  }
  .HeartOnClick {
    height: 40px;
  }
  .Price {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
  }
  .PriceNew {
    font-size: 14px;
  }
  .ProductImage {
    width: 100%;
    height: 170px;
    padding: 0px;
  }
  .Rupee {
    font-size: 16px;
  }

  .PriceOld {
    font-size: 14px;
  }
}
@media screen and (max-width: 379px) {
  .AddToCart {
    width: 60%;
    font-size: 10px;
  }
  .Heart {
    width: 30%;
  }
}
